import React, { ReactElement } from 'react'
import { QA } from 'app/constants'
import { Heading, Paragraph } from '../text'
import './faq-question.scss'

export const FaqQuestion = ({ question, answer }: QA): ReactElement => (
  <div className='faq-question'>
    <Heading className='faq-question__heading' tag='h3' size='28'>
      {question}
    </Heading>
    <Paragraph className='faq-question__paragraph'>{answer}</Paragraph>
  </div>
)
