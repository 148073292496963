import React, { ReactElement } from 'react'
import { QA } from 'app/constants'
import { Heading } from '../text'
import { FaqQuestion } from './faq-question'
import './faq-block.scss'

interface FaqBlockProps {
  title: string
  questions: QA[]
}

export const FaqBlock = ({ title, questions }: FaqBlockProps): ReactElement => (
  <div className='faq-block'>
    <Heading className='faq-block__heading' tag='h2' align='center'>
      {title}
    </Heading>
    {questions.map(({ question, answer }) => (
      <FaqQuestion key={question} question={question} answer={answer} />
    ))}
  </div>
)
