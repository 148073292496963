import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import React, { ReactElement } from 'react'
import { FAQ, Contacts } from 'app/constants'
import { getImageSources, getEmailLink } from 'app/utils'
import { Heading, Paragraph as p } from '../text'
import { FaqBlock } from './faq-block'
import './faq.scss'

export const Faq = (): ReactElement => {
  const {
    xlImage,
    lgImage,
    mdImage,
    smImage,
    xsImage,
  } = useStaticQuery(graphql`
    query {
      xlImage: file(relativePath: { eq: "backgrounds/faq-xl.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      lgImage: file(relativePath: { eq: "backgrounds/faq-lg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      mdImage: file(relativePath: { eq: "backgrounds/faq-md.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      smImage: file(relativePath: { eq: "backgrounds/faq-sm.png" }) {
        childImageSharp {
          fluid(maxWidth: 768, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      xsImage: file(relativePath: { eq: "backgrounds/faq-xs.png" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  const sources = getImageSources({
    type: 'fluid',
    xsImage,
    smImage,
    mdImage,
    lgImage,
    xlImage,
  })

  return (
    <div className='faq'>
      <Image
        className='faq__background'
        fluid={sources}
        imgStyle={{ objectPosition: '50% 100%', height: 'auto' }}
        loading='eager'
      />
      <section className='faq__container'>
        <Heading className='faq__heading' tag='h2' color='white' align='center'>
          Вопросы и ответы
        </Heading>
        <p className='faq__description'>
          Рассказываем, что такое &quot;Крепость&quot; и как она работает. Если
          остались вопросы, вы можете задать их на{' '}
          <a key='email' href={getEmailLink(Contacts.EMAIL)}>
            {Contacts.EMAIL}
          </a>
        </p>
      </section>
      <div className='faq__content'>
        <FaqBlock
          title={FAQ.structure.title}
          questions={FAQ.structure.questions}
        />
        <FaqBlock
          title={FAQ.affected.title}
          questions={FAQ.affected.questions}
        />
        <FaqBlock title={FAQ.helpers.title} questions={FAQ.helpers.questions} />{' '}
      </div>
    </div>
  )
}
