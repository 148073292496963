import React, { ReactElement } from 'react'
import { Layout, SEO } from 'app/components'
import { Faq } from 'app/components/faq'

const FaqPage = (): ReactElement => (
  <Layout headerColors={['white', 'white', 'white', 'white', 'white']}>
    <SEO title='Ответы на вопросы – Крепость' />
    <Faq />
  </Layout>
)

export default FaqPage
